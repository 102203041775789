import React from "react"

import Layout from "../components/layout"

const ThankYou = () => (
  <Layout>
    <div className="container max">
      <div className="row py-5 my-5" style={{
        minHeight: `calc(100vh - 238px)`
      }}>
        <div className="col-12">
          <h2 className="pb-3">THANK YOU</h2>
          <div className="row">

            <div className="col-md-7 pb-5 mb-5">
              <p>Thank you for your inquiry. A representative will contact you as soon as possible.</p>
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="black white-text p-5">
                <h4>DiversiTech Corporation</h4>
                <p>3039 Premiere Parkway<br />
        Suite 600<br />
        Duluth, GA 30097</p>

                <p>(800) 995-2222</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
